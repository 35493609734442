<template>
    <div class="pb-4 md:pt-8">
        <div class="mx-2 md:mx-20 lg:mx-40 xl:mx-70 2xl:mx-80">
            <div
                v-text="name"
                class="page-title text-4xl mb-2 sm:mb-4"
            />
            <slot />
        </div>
    </div>
</template>

<script>
    // Library
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'NormalPage',

        props: {
            name: {
                type: String,
                required: true,
            },
        },
    });
</script>
