<template>
    <normal-page name="Reviews">
        <div
            v-for="(review, i) in reviews"
            :key="i"
            class="mb-6 sm:mb-10 clickable"
            @click="goToReview(review.url)"
        >
            <review-component :review="review" />
        </div>
    </normal-page>
</template>

<script>
    // Components
    import { defineComponent } from 'vue';
    import NormalPage from '@/components/layout/pages/NormalPage';
    import ReviewComponent from '@/components/layout/ReviewComponent';
    // Library
    import store from '@/store/store';

    export default defineComponent({
        name: 'ReviewsView',

        components: {
            NormalPage,
            ReviewComponent,
        },

        setup() {
            const goToReview = (url) => {
                window.open(url);
            };

            return {
                reviews: store.getters.reviews,
                goToReview,
            };
        },
    });
</script>
