<template>
    <a class="text-left block h-full w-full" :href="review.url">
        <span class="italic">
            &ldquo;<span v-html="review.quote" />&rdquo;
        </span>
        <br />
        <span class="whitespace-nowrap ml-2">
            - <span v-text="review.name" />
        </span>
    </a>
</template>

<script>
    // Models
    import Review from '@/models/Review.model';
    // Library
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'ReviewComponent',

        props: {
            review: {
                type: Review,
                required: true,
            },
        },
    });
</script>
