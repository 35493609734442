<template>
    <nav-bar />
    <div class="main flex text-base pt-9 sm:text-lg pb-14 sm:pb-20 sm:pt-14 lg:pt-16">
        <router-view />
    </div>

    <footer-bar />
</template>

<script>
    // Components
    import NavBar from '@/components/layout/NavBar';
    import FooterBar from '@/components/layout/FooterBar';

    export default {
        name: 'App',

        components: {
            NavBar,
            FooterBar,
        },
    };
</script>
