<template>
    <div
        class="youtube"
        :style="{ height: height + 'px', width: (height * 1.5625) + 'px' }"
        @click="onLoadVideo"
    >
        <div>
            <v-lazy-image
                v-if="!isLoadVideo"
                :src="'https://img.youtube.com/vi/' + video.id + '/sddefault.jpg'"
            />

            <div
                v-text="video.title"
                class="text-white absolute h-6 px-2 text-sm text-center w-full"
                style="bottom: 0; overflow-x: hidden;"
            />
        </div>

        <div
            v-if="!isLoadVideo"
            class="play-button"
            @click="onLoadVideo"
        />

        <template v-if="isLoadVideo">
            <iframe
                :height="height"
                :width="(height * 1.5625)"
                :src="'https://www.youtube.com/embed/' + video.id + '?&autoplay=1&enablejsapi=1'"
                title="YouTube video player"
                allowfullscreen
            />
        </template>
    </div>
</template>

<script>
    // Models
    // Library
    import { defineComponent, ref } from 'vue';
    import VLazyImage from 'v-lazy-image';
    import Video from '@/models/Video.model';

    export default defineComponent({
        name: 'YoutubePlayer',
        // TODO: Use youtube iFrame api https://developers.google.com/youtube/iframe_api_reference

        components: {
            VLazyImage,
        },

        props: {
            video: {
                type: Video,
                required: true,
            },

            height: {
                type: Number,
                default: 300,
            },
        },

        setup(props, { emit }) {
            const isLoadVideo = ref(false);

            const onLoadVideo = () => {
                isLoadVideo.value = true;

                emit('loadVideo');
            };

            return {
                isLoadVideo,
                onLoadVideo,
            };
        },
    });
</script>

<style>
    .youtube {
        background-color: #000;
        position: relative;
        overflow: hidden;
    }

    .youtube img {
        width: 100%;
        top: -16.82%;
        left: 0;
        opacity: 0.7;
    }

    .youtube .play-button {
        width: 90px;
        height: 60px;
        background-color: #333;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
        z-index: 1;
        opacity: 0.8;
        border-radius: 6px;
    }

    .youtube .play-button:before {
        content: '';
        border-style: solid;
        border-width: 15px 0 15px 26px;
        border-color: transparent transparent transparent #fff;
    }

    .youtube,
    .youtube img,
    .youtube .play-button {
        cursor: pointer;
    }

    .youtube img,
    .youtube iframe,
    .youtube .play-button,
    .youtube .play-button:before {
        position: absolute;
    }

    .youtube .play-button,
    .youtube .play-button:before {
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        z-index: 0;
    }

    .youtube iframe {
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }
</style>
