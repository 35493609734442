import { createApp } from 'vue';
import VueGtag from 'vue-gtag';
import App from '@/App';

import router from '@/router/router';
import store from '@/store/store';
import './sass/app.sass';

createApp(App)
    .use(router)
    .use(store)
    .use(VueGtag, {
        appName: 'Funus',
        pageTrackerScreenviewEnabled: true,
        pageTrackerTemplate(to) {
            return { page_title: to.name, page_path: to.path };
        },
        config: { id: 'G-K0R7MN766T' },
    }, router)
    .mount('#app');
