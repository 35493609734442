<template>
    <iframe
        :title="name"
        style="width: 280px; height: 150px; margin: 0 auto;"
        :src="getUrl()"
    />
</template>

<script>
    // Library
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'BandcampPlayer',

        props: {
            name: {
                type: String,
                required: true,
            },

            route: {
                type: String,
                required: true,
            },

            isTrack: {
                type: Boolean,
                required: false,
            },
        },

        methods: {
            getUrl() {
                return `https://bandcamp.com/EmbeddedPlayer/${this.isTrack ? 'track' : 'album'}=${this.route}/size=large/bgcol=2a2a2a/linkcol=e32c14/tracklist=false/artwork=small/transparent=true/`;
            },
        },
    });
</script>
