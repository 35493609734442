<template>
    <normal-page name="About">
        <p class="mb-4">
            Funus - founded in 2006, The Netherlands - is a Post Rock/Shoegaze (former Black Metal) music project of songwriter and multi-instrumentalist Ruben Vermeulen.
        </p>
        <p class="mb-4">
            Lyrics and music are blended to express stretched feelings of loneliness, self-confrontation and ways of coping with change – a melancholic world one sooner or later has to deal with in
            life.
        </p>
        <p class="mb-4">
            Initially Ruben collaborated with several musicians to perform live on stage. With different line ups the band had a lot of gigs during the period of 2009-2011. Funus' first published work
            is the independently released promotion EP “Funus” (2009). In 2011 the test recording “Rosefield Road” was spread as promotion material.
        </p>
        <p class="mb-4">
            In 2012 album “Adrift Alone” was written to create something different: a concept album about the five stages of loss and grief by psychologist Elisabeth Kübler-Ross. This album
            experiments with a fusion of Black Metal, Doom Metal and acoustic guitars.<br />
            “Adrift Alone” was released in 2016. It received excellent reviews from e-zines throughout Europe. The test recording of “Rosefield Road” got its definitive version on this album.
        </p>
        <p class="mb-4">
            In 2020 Funus started writing material for a new release. Inspired by introspection, a new full length album is shaped with appearances of special guest singer Sabina Knol.<br />
            An introduction for this new release was given with single “Would You”, which was released in 2021 on all streaming platforms, including a music video on YouTube.
        </p>
        <p>
            With hazy clean vocals, noisy guitars, soft acoustic guitar playing and beautiful atmospheric soundscapes, Funus delivers seven tracks with newest album “MONO” on February 25th 2023.
        </p>
    </normal-page>
</template>

<script>
    // Components
    import NormalPage from '@/components/layout/pages/NormalPage';

    export default {
        components: {
            NormalPage,
        },
    };
</script>
