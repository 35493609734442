// Library
import { createRouter, createWebHistory } from 'vue-router';
// Views
import HomeView from '@/views/HomeView';
import AboutView from '@/views/AboutView';
import ReleasesView from '@/views/ReleasesView';
import VideosView from '@/views/VideosView';
import PressView from '@/views/PressView';
import ReviewsView from '@/views/ReviewsView';
import ShopView from '@/views/ShopView';
import ContactView from '@/views/ContactView';

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/about',
        name: 'about',
        component: AboutView, // import(/* webpackChunkName: "about" */ '@/views/AboutView.vue'),
    },
    {
        path: '/releases',
        name: 'releases',
        component: ReleasesView, // import(/* webpackChunkName: "releases" */ '@/views/ReleasesView.vue'),
    },
    {
        path: '/videos',
        name: 'videos',
        component: VideosView, // import(/* webpackChunkName: "video" */ '@/views/VideosView.vue'),
    },
    {
        path: '/press',
        name: 'press',
        component: PressView, // import(/* webpackChunkName: "reviews" */ '@/views/ReviewsView.vue'),
    },
    {
        path: '/reviews',
        name: 'reviews',
        component: ReviewsView, // import(/* webpackChunkName: "reviews" */ '@/views/ReviewsView.vue'),
    },
    {
        path: '/shop',
        name: 'shop',
        component: ShopView, // import(/* webpackChunkName: "shop" */ '@/views/ShopView.vue'),
    },
    {
        path: '/contact',
        name: 'contact',
        component: ContactView, // import(/* webpackChunkName: "contact" */ '@/views/ContactView.vue'),
    },
    {
        path: '/:catchAll(.*)',
        name: 'error-404',
        component: () => import(/* webpackChunkName: "error-404" */ '@/views/Error404View'),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
