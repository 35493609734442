// Models
import { createStore } from 'vuex';
import Review from '@/models/Review.model';
import Release from '@/models/Release.model';
import Video from '@/models/Video.model';
// Library
import Social from '@/models/Social.model';

const store = createStore({
    state: {
        releases: [
            new Release(
                'Mono',
                'Second full length album.',
                7,
                'Feb 25th, 2023',
                'Released on streaming platforms & limited digipack release',
                Release.playerTypes.spotify,
                '2FnHhHf1nQTl0g9u2VVPOj',
            ),
            new Release(
                'Adrift Alone',
                'Debut concept album about Elisabeth Kübler-Ross’ five stages of loss and grief.',
                5,
                'April 30th, 2016',
                'Digipack, 8 page booklet',
                Release.playerTypes.spotify,
                '1VfuGw7d7KQkOOkw2Tl1kV',
            ),
            new Release(
                'Rosefield Road',
                'Promotional single',
                1,
                'April 15th, 2011',
                'Released on streaming platforms',
                Release.playerTypes.spotify,
                '2udu1bXuFZy8GChyCmYzo4',
            ),
            new Release(
                'Funus',
                'Self-titled debut EP',
                3,
                'October 30th, 2009',
                'Jewelcase, 1 page booklet (sold out)',
                Release.playerTypes.spotify,
                '3Rod25DOODqIXK1xwIvvut',
            ),
        ],

        videos: [
            new Video('vE9OYDPr1WE', 'FUNUS: "Embracings Relived" [Official Music Video]'),
            new Video('yhf640udy2M', 'FUNUS: "Never Heard" [Official Music Video]'),
            new Video('iJ6tVbcsWBo', 'FUNUS: BAND DEVELOPMENT SUMMARY 2006-2021 - 15TH ANNIVERSARY VIDEO'),
            new Video('YQvH4t44U1c', 'FUNUS: "Adrift Alone", full album (2016)'),
            new Video('hHAFJ0CDh_s', 'FUNUS: "Rosefield Road", Rehearsal Demo (2011)'),
            new Video('DJE2i3lfgSk', 'FUNUS: "Funus", Promotional Demo/EP (2009)'),
        ],

        reviews: [
            new Review(
                'Prog Sphere',
                'https://www.prog-sphere.com/2024/01/16/2927/',
                'Funus Unveiled: Navigating the Post Rock Seas with Ruben Vermeulen.',
            ),
            new Review(
                'Progwereld (Dutch)',
                'https://www.progwereld.org/recensie/funus-mono/',
                'There is a good chance that you will discover something new every time. Music as music is meant to be: discover!',
            ),
            new Review(
                '7.6/10, GRIMM Gent',
                'https://www.grimmgent.com/albumreviews/funus-mono',
                'Funus‘ attention to these tiny details really enhances his attempt to recapture that bygone vibe from a few decades ago.',
            ),
            new Review(
                '9/10, Ever Metal',
                'https://www.ever-metal.com/2023/02/28/funus-mono',
                'A record that is absolutely superb in execution, performance and production, dripping with emotion and pathos yet not overdoing it,'
                + ' which is both rare and precious and shows a very keen appreciation of both is own music, and his audience as well. Bravo!',
            ),
            new Review(
                '88/100, Zware Metalen (Dutch)',
                'https://zwaremetalen.com/albumrecensies/funus-mono',
                'This is a listening record, one that is performed beautifully and has the potential of growth as the times of listening increases.',
            ),
            new Review(
                'Weirdo Shrine',
                'https://weirdoshrine.wordpress.com/2023/02/06/review-qa-funus-mono-2023-self-released',
                'MONO shows us the grand, open views of metal, but adds believability and depth.',
            ),
            new Review(
                'Sentinal Daily',
                'https://sentineldaily.com.au/funus-mono-own-label',
                'MONO is able to communicate a full spectrum of moods and feeling.',
            ),
            new Review(
                '8.1/10, Pitkings',
                'https://www.pitkings.nl/funus-adrift-alone-album-review',
                'The music of Funus is evil and fast, but also slow and agonizing with dark atmospheres.'
                + '<br/>It’s like a sick and depressive journey that makes you listen to the album time after time!',
            ),
            new Review(
                'Terra Relicta',
                'https://terrarelicta.com/index.php/reviews/all-reviews/5919-funus-adrift-alone-2016-review',
                'It\'s a dark morbid story that never ends raging up and down between the stages of grief, sorrow and depression.',
            ),
            new Review(
                '95/100, Metalfan',
                'https://www.metalfan.nl/reviews.php?id=9398',
                'Funus bewijst dat er binnen de black metal nog steeds muziek valt te maken die zowel vernieuwend als vertrouwd klinkt.',
            ),
        ],

        socials: [
            new Social(
                'Spotify',
                'https://open.spotify.com/artist/1PAGxgClcCVaHyPvUS1Dx9?si=xFe2sGHPSjCJWWLL6LRyOA',
            ),
            new Social(
                'Bandcamp',
                'https://funusbandofficial.bandcamp.com',
            ),
            new Social(
                'Soundcloud',
                'https://soundcloud.com/user-666584185/sets',
            ),
            new Social(
                'YouTube',
                'https://youtube.com/user/VOIDOFFUNUS',
            ),
            // new Social(
            //     'Facebook',
            //     'https://facebook.com/funusbandofficial',
            // ),
            // new Social(
            //     'Instagram',
            //     'https://instagram.com/funusbandofficial',
            // ),
        ],

        shopFormId: '1FAIpQLSfkv2jHbqhm_tITYha7dVwR-SXbj-iYtr5748DWhAb9HUNCMw',
    },

    getters: {
        releases(state) {
            return state.releases;
        },

        videos(state) {
            return state.videos;
        },

        reviews(state) {
            return state.reviews;
        },

        socials(state) {
            return state.socials;
        },

        shopFormId(state) {
            return state.shopFormId;
        },
    },
});

export default store;
