<template>
    <div id="smoke-canvas" class="absolute top-0 left-0 w-full h-full" style="z-index: -1;" />
</template>

<script>
    import Smoke from '@/models/Smoke.model';
    import { onMounted, onUnmounted } from 'vue';

    export default {
        name: 'SmokeComponent',

        setup() {
            let smoke = null;

            onMounted(() => {
                smoke = new Smoke().init();
            });

            onUnmounted(() => {
                smoke?.destroy();
            });
        },
    };
</script>
