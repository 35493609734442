<template>
    <div class="pb-4 md:pt-8">
        <div
            v-text="name"
            class="page-title text-4xl mb-2 sm:mb-4 ml-2 md:ml-20 lg:ml-40 xl:ml-80 2xl:mx-120"
        />
        <div class="mx-2 md:mx-6 lg:mx-10">
            <slot />
        </div>
    </div>
</template>

<script>
    // Library
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'WidePage',

        props: {
            name: {
                type: String,
                required: true,
            },
        },
    });
</script>
