export default class Release {
    // region STATIC PROPERTIES
    static playerTypes = {
        spotify: 'spotify',
        bandcamp: 'bandcamp',
        soundCloud: 'soundcloud',
    };

    // endregion STATIC PROPERTIES

    constructor(
        name,
        description,
        songCount,
        releasedAt,
        releaseformat,
        playerType = null,
        playerRoute = null,
    ) {
        this.name = name;
        this.description = description;
        this.songCount = songCount;

        this.releasedAt = releasedAt;
        this.releaseformat = releaseformat;

        this.playerType = playerType;
        this.playerRoute = playerRoute;
    }

    // region GETTERS
    isSoundCloud() {
        return (this.playerType === Release.playerTypes.soundCloud);
    }

    isBandcamp() {
        return (this.playerType === Release.playerTypes.bandcamp);
    }

    isSpotify() {
        return (this.playerType === Release.playerTypes.spotify);
    }

    getImageSrc() {
        return `img/releases/${this.name.toLowerCase()
            .replace(' ', '_')}-240.jpg`;
    }

    // endregion GETTERS
}
