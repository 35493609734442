<template>
    <normal-page name="Contact">
        <button
            v-if="!email"
            type="button"
            @click="showEmail"
            class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full ml-4"
        >
            Show email
        </button>

        <a
            class="ml-4"
            v-if="email"
            :href="'mailto:' + email"
            v-text="email"
        />
    </normal-page>
</template>

<script>
    // Components
    import { defineComponent, ref } from 'vue';
    import NormalPage from '@/components/layout/pages/NormalPage';
    // Library

    export default defineComponent({
        name: 'ContactView',

        components: {
            NormalPage,
        },

        setup() {
            const email = ref('');

            const showEmail = () => {
                const something = '@';

                email.value = ['funusbookings', something, 'gmail.com'].join('');
            };

            return {
                showEmail,
                email,
            };
        },
    });
</script>
