import * as THREE from 'three';
import { random } from 'lodash';

// TODO: Merge this into SmokeComponent
export default class Smoke {
    isDestroyed = false;

    smokeParticles;

    renderer;
    scene;
    camera;

    clock;

    mesh;
    meshGeometry;
    meshMaterial;

    texture;
    textureGeometry;
    textureMaterial;

    light;

    constructor() {
        this.onResize = this.onResize.bind(this);
        window.addEventListener('resize', this.onResize);
    }

    init() {
        this.cubeSineDriver = 0;
        this.clock = new THREE.Clock();

        this.renderer = new THREE.WebGLRenderer();
        this.renderer.setSize(window.innerWidth, window.innerHeight);

        this.scene = new THREE.Scene();

        this.meshGeometry = new THREE.BufferGeometry(200, 200, 200);
        this.meshMaterial = new THREE.MeshLambertMaterial({ color: 0xaa6666 });

        this.mesh = new THREE.Mesh(this.meshGeometry, this.meshMaterial);

        this.addCamera();
        this.addLights();
        this.addParticles();

        document.getElementById('smoke-canvas')
            .appendChild(this.renderer.domElement);

        this.update();

        return this;
    }

    evolveSmoke(delta) {
        let smokeParticlesLength = this.smokeParticles.length;

        while (smokeParticlesLength--) {
            const speed = [0.2, 0.2, 0.3, 0.3, 0.5][random(0, 4)];

            this.smokeParticles[smokeParticlesLength].rotation.z += delta * speed;
        }
    }

    addLights() {
        this.light = new THREE.DirectionalLight(0x9159c5, 1.1);

        this.light.position.set(-1, 0, 1);
        this.scene.add(this.light);
    }

    addCamera() {
        this.camera = new THREE.PerspectiveCamera(
            75,
            window.innerWidth / window.innerHeight,
            1,
            10000,
        );
        this.camera.position.z = 1000;

        this.scene.add(this.camera);
    }

    addParticles() {
        this.smokeParticles = [];

        new THREE.TextureLoader().load(
            'img/layout/clouds.png',
            (texture) => {
                this.textureMaterial = new THREE.MeshLambertMaterial({
                    color: 0xffffff,
                    map: texture,
                    transparent: true,
                });
                this.textureMaterial.map.minFilter = THREE.LinearFilter;

                this.textureGeometry = new THREE.PlaneBufferGeometry(300, 300);

                const smokeMeshes = [];
                let limit = 150;

                while (limit--) {
                    smokeMeshes[limit] = new THREE.Mesh(
                        this.textureGeometry,
                        this.textureMaterial,
                    );
                    smokeMeshes[limit].position.set(
                        Math.random() * 500 - 250,
                        Math.random() * 500 - 250,
                        Math.random() * 1000 - 100,
                    );
                    smokeMeshes[limit].rotation.z = Math.random() * 360;
                    this.smokeParticles.push(smokeMeshes[limit]);
                    this.scene.add(smokeMeshes[limit]);
                }
            },
        );
    }

    render() {
        this.cubeSineDriver += 0.01;

        this.mesh.rotation.x += 0.005;
        this.mesh.rotation.y += 0.01;
        this.mesh.position.z = 100 + Math.sin(this.cubeSineDriver) * 500;

        this.renderer.render(this.scene, this.camera);
    }

    update() {
        if (!this.isDestroyed) {
            this.evolveSmoke(this.clock.getDelta());
            this.render();

            requestAnimationFrame(this.update.bind(this));
        }
    }

    onResize() {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(window.innerWidth, window.innerHeight);
    }

    destroy() {
        this.isDestroyed = true;

        window.removeEventListener('resize', this.onResize);

        this.renderer?.dispose();

        this.textureGeometry?.dispose();
        this.textureMaterial?.dispose();
        this.texture?.dispose();

        this.light?.dispose();

        this.meshGeometry?.dispose();
        this.meshMaterial?.dispose();
    }
}
