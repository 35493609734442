<template>
    <normal-page name="Shop">
        <g-form-component
            :form-id="formId"
            :height="990"
            loading-text="Loading shop..."
        />
    </normal-page>
</template>

<script>
    // Components
    import NormalPage from '@/components/layout/pages/NormalPage';
    import GFormComponent from '@/components/plugins/GFormComponent';
    // Library
    import store from '@/store/store';

    export default {
        components: {
            NormalPage,
            GFormComponent,
        },

        setup() {
            return {
                formId: store.getters.shopFormId,
            };
        },
    };
</script>
