<template>
    <wide-page name="Press">
        <div class="grid sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 gap-y-8 sm:gap-y-12 xl:gap-x-16">
            <template
                v-for="(isLoaded, imagePath) in imagePaths1"
                :key="imagePath"
            >
                <enlarge-image
                    :src="imagePath"
                    :height="imageHeight"
                    :isForceLoadFull="Object.values(imagePaths1).every(isLoaded => isLoaded)"
                    @thumbnailLoaded="imagePaths1[imagePath] = true"
                />
            </template>
        </div>

        <div class="grid mt-12 mb-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-y-8 sm:gap-y-12 xl:gap-x-16">
            <template
                v-for="(isLoaded, imagePath) in imagePaths2"
                :key="imagePath"
            >
                <enlarge-image
                    :src="imagePath"
                    :height="imageHeight"
                    :isForceLoadFull="Object.values(imagePaths2).every(isLoaded => isLoaded)"
                    @thumbnailLoaded="imagePaths2[imagePath] = true"
                />
            </template>
        </div>
    </wide-page>
</template>

<script>
    // Components
    import { defineComponent, onUnmounted, ref } from 'vue';
    import debounce from 'lodash/debounce';
    import WidePage from '@/components/layout/pages/WidePage';
    import EnlargeImage from '@/components/plugins/EnlargeImage';
    // Library

    export default defineComponent({
        name: 'VideosView',

        components: {
            EnlargeImage,
            WidePage,
        },

        setup() {
            const imageHeight = ref(300);

            const calculateHeight = () => {
                if (document.documentElement.clientWidth < 420) {
                    imageHeight.value = 230;// < xs
                } else if (document.documentElement.clientWidth < 530) {
                    imageHeight.value = 250;// < sm
                } else if (document.documentElement.clientWidth < 768) {
                    imageHeight.value = 250;// < sm
                } else if (document.documentElement.clientWidth < 790) {
                    imageHeight.value = 160;// < sm
                } else if (document.documentElement.clientWidth < 850) {
                    imageHeight.value = 170;// < sm
                } else if (document.documentElement.clientWidth < 920) {
                    imageHeight.value = 180;// < sm
                } else if (document.documentElement.clientWidth < 1024) {
                    imageHeight.value = 200;// < md
                } else if (document.documentElement.clientWidth < 1150) {
                    imageHeight.value = 210;// < md
                } else if (document.documentElement.clientWidth < 1250) {
                    imageHeight.value = 240;// lg
                } else if (document.documentElement.clientWidth < 1536) {
                    imageHeight.value = 250;// lg
                } else {
                    imageHeight.value = 250; // default
                }
            };

            const onResize = debounce(() => {
                calculateHeight();
            }, 10);

            window.addEventListener('resize', onResize);

            onUnmounted(() => {
                window.removeEventListener('resize', onResize);
            });

            calculateHeight();

            const imagePaths1 = ref({
                'img/press/logo-shade-background.jpg': false,
                'img/press/logo-shade.jpg': false,
                'img/press/logo-clean-background.jpg': false,
                'img/press/logo-clean-outlined.jpg': false,
            });

            const imagePaths2 = ref({
                'img/press/press-3.jpg': false,
                'img/press/press-1.jpg': false,
                'img/press/press-2.jpg': false,
            });

            return {
                imageHeight,
                imagePaths1,
                imagePaths2,
            };
        },
    });
</script>
