<template>
    <!-- <div class="home bg-size-70 sm:bg-size-70 lg:bg-size-49 xl:bg-size-43 2xl:bg-size-40">-->
    <div class="home bg-size-85 sm:bg-size-56 lg:bg-size-46 xl:bg-size-37 2xl:bg-size-32">
        <div class="h-full w-full pb-28 hover:cursor-pointer" @click="$router.push({ name: 'shop' })" />

        <div class="home-reviews text-base sm:text-2xl px-4 lg:text-xl xl:text-2xl bg-black">
            <div class="relative overflow-hidden">
                <transition name="slide">
                    <p
                        v-if="isShowReview"
                        class="mx-auto table clickable"
                        @click="$router.push({ name: 'reviews' })"
                    >
                        <review-component :review="currentReview" />
                    </p>
                </transition>
            </div>
        </div>

        <smoke-component />

        <v-lazy-image
            src="img/layout/background1.jpg"
            class="flicker-bg object-cover"
            :class="{ visible: this.isBgFlickerVisible }"
        />
    </div>
</template>

<script>
    // Components
    import { defineComponent, onMounted, ref } from 'vue';
    import VLazyImage from 'v-lazy-image';
    import ReviewComponent from '@/components/layout/ReviewComponent';
    import SmokeComponent from '@/components/plugins/SmokeComponent';
    // Library
    import store from '@/store/store';

    export default defineComponent({
        name: 'HomeView',

        components: {
            SmokeComponent,
            ReviewComponent,
            VLazyImage,
        },

        setup() {
            const isBgFlickerVisible = ref(false);
            const { reviews } = store.getters;
            const isShowReview = ref(true);
            const currentReview = ref(reviews[0]);
            const reviewI = ref(0);

            onMounted(() => {
                setInterval(() => {
                    if (Math.floor(Math.random() * 20) === 0) {
                        isBgFlickerVisible.value = true;

                        setTimeout(() => {
                            isBgFlickerVisible.value = false;
                        }, 200);
                    }
                }, 300);

                setInterval(() => {
                    isShowReview.value = false;
                    if (reviewI.value < (reviews.length - 1)) {
                        reviewI.value += 1;
                    } else {
                        reviewI.value = 0;
                    }

                    setTimeout(() => {
                        isShowReview.value = true;
                    }, 300);

                    currentReview.value = reviews[reviewI.value];
                }, 6000);
            });

            return {
                isBgFlickerVisible,
                isShowReview,
                currentReview,
            };
        },
    });
</script>

<style lang="sass">
    .home-reviews
        position: absolute
        width: 99%
        bottom: 6rem
        text-align: center
        box-shadow: 0 0 1rem 1rem black
    // min-width: 350px

    .flicker-bg
        max-width: 100%
        height: 100%
        max-height: 100%
        width: 100%
        position: absolute
        z-index: -1
        top: 0
        left: 0
        opacity: 0

        &.visible
            opacity: 0.1

    .slide-enter-active,
    .slide-enter-active,
    .slide-leave-active
        transition: all 1.5s ease-in-out

    .slide-enter-from
        transform: translateX(-500px)
        opacity: 0

    .slide-leave-to
        transform: translateX(500px)
        opacity: 0
</style>
