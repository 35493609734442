<template>
    <iframe
        :title="id"
        :src="'https://open.spotify.com/embed/album/' + id + '?utm_source=generator&theme=0'"
        width="100%"
        :height="height"
        style="border-radius:12px"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
    />
</template>

<script>
    // Library
    import { computed, defineComponent } from 'vue';

    export default defineComponent({
        name: 'SoundCloudPlayer',

        props: {
            id: {
                type: String,
                required: true,
            },

            songCount: {
                type: Number,
                default: 0,
            },
        },

        setup(props) {
            const height = computed(() => Math.min(238, Math.max(238, (80 + (31 * props.songCount)))));

            return {
                height,
            };
        },
    });
</script>
