<template>
    <wide-page name="Releases">
        <div
            class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 content-center"
            :class="{ [gapsY]: true, [gapsX]: true }"
        >
            <template
                v-for="(release, i) in releases"
                :key="i"
            >
                <div>
                    <div
                        v-text="release.name"
                        class="text-2xl"
                    />

                    <v-lazy-image
                        :src="release.getImageSrc()"
                        class="my-2 mx-auto image-shadow-red max-h-60 sm:max-h-70"
                    />

                    <div
                        v-text="release.description"
                        class="c-text-alt italic pl-2 description md:h-14 lg:h-20 xl:h-16 2xl:h-12 text-lg"
                    />

                    <table class="w-full m-2 text-base md:h-10 lg:h-14 xl:h-16">
                        <tr>
                            <td class="text-left">Release: <span class="italic">{{ release.releasedAt }}</span></td>
                            <td class="text-right">Format: <span class="italic">{{ release.releaseformat }}</span></td>
                        </tr>
                    </table>

                    <div>
                        <spotify-player
                            v-if="release.isSpotify()"
                            :id="release.playerRoute"
                            :song-count="release.songCount"
                        />

                        <bandcamp-player
                            v-if="release.isBandcamp()"
                            :route="release.playerRoute"
                            :is-track="true"
                            :name="release.name"
                        />
                    </div>
                </div>
            </template>
        </div>
    </wide-page>
</template>

<script>
    // Components
    import VLazyImage from 'v-lazy-image';
    import { defineComponent, ref } from 'vue';
    import WidePage from '@/components/layout/pages/WidePage';
    import SpotifyPlayer from '@/components/players/SpotifyPlayer';
    import BandcampPlayer from '@/components/players/BandcampPlayer';
    // Library
    import store from '@/store/store';

    export default defineComponent({
        name: 'ReleasesView',

        components: {
            BandcampPlayer,
            WidePage,
            VLazyImage,
            SpotifyPlayer,
        },

        setup() {
            const gapsY = ref([
                'gap-y-8', 'sm:gap-y-10', '2xl:gap-y-12',
            ].join(' '));

            const gapsX = ref([
                'gap-x-20', '2xl:gap-x-12',
            ].join(' '));

            return {
                gapsX,
                gapsY,
                releases: store.getters.releases,
            };
        },
    });
</script>
