<template>
    <footer class="flex fixed items-center bg-body no-select w-full bottom-0">
        <table class="w-full mx-auto" style="max-width: 50rem;">
            <tr>
                <td v-for="(social, i) in socials" :key="i" class="text-center">
                    <a
                        :href="social.url"
                        :aria-label="social.name"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <v-lazy-image
                            :src="`img/socials/${social.name.toLowerCase()}.svg`"
                            class="social-icon inline-block"
                            :title="social.name"
                        />
                    </a>
                </td>
            </tr>
        </table>
    </footer>
</template>

<script>
    // Library
    import { defineComponent } from 'vue';
    import VLazyImage from 'v-lazy-image';
    import store from '@/store/store';

    export default defineComponent({
        name: 'FooterBar',

        components: {
            VLazyImage,
        },

        setup() {
            return {
                socials: store.getters.socials,
            };
        },
    });
</script>

<style lang="sass">
    .social-icon
        height: 2.3rem
        width: 2.3rem
        opacity: 0.8

    a
        .social-icon:hover
            filter: invert(50%) sepia(90%) saturate(9000%) contrast(0.6)
            opacity: 1
</style>
