<template>
    <div class="enlargeable-image">
        <div
            ref="thumbnail"
        >
            <img
                :src="compSrc"
                class="thumbnail-image mx-auto image-shadow-red cursor-zoom-in"
                alt="default"
                :style="{ height: height + 'px' }"
                @load="$emit('thumbnail-loaded')"
                @click="enlarge"
            />
        </div>

        <div
            class="absolute top-0 left-0 w-full h-full"
            :class="{ hidden: !isEnlarged }"
            style="backdrop-filter: blur(1px) grayscale(0.2); z-index: 1;"
        />

        <div
            style="transition-property: width, height, left, top; transition-duration: 0.3s;"
            class="full items-center content-center bg-center bg-contain bg-no-repeat z-10"
            :style="styles"
            :class="{
                'enlarged fixed left-0 w-full h-3/4': isEnlarged,
                hidden: !isEnlarged,
                'cursor-zoom-out': !isShrinking,
                'cursor-zoom-in': isShrinking,
            }"
            @click="enlarge(true)"
        />

        <img
            v-if="(isLoadFull || isForceLoadFull) && !isFullLoaded"
            :src="src"
            alt="full"
            class="absolute"
            style="top: -666666px"
            @load="onLoaded"
        />
    </div>
</template>

<script>
    export default {
        name: 'EnlargeImage',

        props: {
            src: {
                type: String,
                default: '',
            },

            height: {
                type: Number,
                default: 300,
            },

            isForceLoadFull: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                isEnlarged: false,
                isShrinking: false,
                isLoadFull: false,
                isFullLoaded: false,
                styles: this.styles,
                transitionValue: '',
                timer: null,
                imageLoadedCount: 0,
            };
        },

        computed: {
            compSrc() {
                return (this.isFullLoaded ? this.src : this.getThumbPath());
            },
        },

        methods: {
            getThumbPath() {
                return this.src.replace('.', '_thumb.');
            },

            onLoaded() {
                this.isFullLoaded = true;

                this.styles = {
                    backgroundImage: `url(${this.compSrc})`,
                };
            },

            enlarge(isReset) {
                clearTimeout(this.timer);

                const rect = this.$refs.thumbnail.getBoundingClientRect();
                this.styles = {
                    backgroundImage: `url(${this.compSrc})`,
                    left: `${Math.round(rect.left)}px`,
                    top: `${Math.round(rect.top)}px`,
                    width: `${Math.round(rect.right - rect.left)}px`,
                    height: `${Math.round(rect.bottom - rect.top)}px`,
                };

                if (isReset && this.isShrinking) {
                    this.isEnlarged = false;
                }

                if (!this.isEnlarged) {
                    this.isShrinking = false;
                    this.isEnlarged = true;

                    this.timer = setTimeout(() => {
                        this.styles = {
                            top: '12%',
                            backgroundImage: `url(${this.compSrc})`,
                        };

                        this.isEnlarged = true;
                    }, 0);
                } else {
                    this.isShrinking = true;

                    this.timer = setTimeout(() => {
                        this.isEnlarged = false;
                    }, 300);
                }
            },
        },
    };
</script>
