<template>
    <wide-page name="Videos">
        <div class="grid mb-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-y-8 sm:gap-y-12 xl:gap-x-16 content-center">
            <template
                v-for="(video, i) in videos"
                :key="i"
            >
                <div class="m-auto image-shadow-red">
                    <youtube-player
                        :id="`video-${video.id}`"
                        :video="video"
                        :height="videoHeight"
                        @click="pauseOtherVideos(video.id)"
                        @load-video="pauseOtherVideos(video.id)"
                    />
                </div>
            </template>
        </div>
    </wide-page>
</template>

<script>
    // Components
    import { defineComponent, onUnmounted, ref } from 'vue';
    import debounce from 'lodash/debounce';
    import WidePage from '@/components/layout/pages/WidePage';
    import YoutubePlayer from '@/components/players/YoutubePlayer';
    // Library
    import store from '@/store/store';

    export default defineComponent({
        name: 'VideosView',

        components: {
            WidePage,
            YoutubePlayer,
        },

        setup() {
            const videoHeight = ref(300);

            const calculateHeight = () => {
                if (document.documentElement.clientWidth < 420) {
                    videoHeight.value = 180;// < xs
                } else if (document.documentElement.clientWidth < 530) {
                    videoHeight.value = 250;// < sm
                } else if (document.documentElement.clientWidth < 768) {
                    videoHeight.value = 300;// < sm
                } else if (document.documentElement.clientWidth < 1024) {
                    videoHeight.value = 210;// < md
                } else if (document.documentElement.clientWidth < 1170) {
                    videoHeight.value = 180;// < md
                } else if (document.documentElement.clientWidth < 1536) {
                    videoHeight.value = 210;// lg
                } else {
                    videoHeight.value = 250; // default
                }
            };

            const onResize = debounce(() => {
                calculateHeight();
            }, 10);

            window.addEventListener('resize', onResize);

            onUnmounted(() => {
                window.removeEventListener('resize', onResize);
            });

            calculateHeight();

            const { videos } = store.getters;
            const pauseOtherVideos = (currentVideoId) => {
                videos.forEach((video) => {
                    if (video.id !== currentVideoId) {
                        const videoFrame = document
                            .getElementById(`video-${video.id}`)
                            ?.getElementsByTagName('iframe')[0];

                        videoFrame?.contentWindow
                            .postMessage(
                                '{"event":"command","func":"pauseVideo","args":""}',
                                '*',
                            );
                    }
                });
            };

            return {
                videoHeight,
                videos,
                pauseOtherVideos,
            };
        },
    });
</script>
