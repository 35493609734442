<template>
    <div
        class="w-full mt-10 h-full text-2xl text-center"
        :class="{ hidden: isLoaded }"
    >
        {{ loadingText }}
    </div>
    <iframe
        class="w-full text-white rounded-2xl bg-white"
        :class="{ invisible: !isLoaded }"
        style="max-width: 675px;"
        :title="formId"
        :src="`https://docs.google.com/forms/d/e/${formId}/viewform?embedded=true`"
        :height="height"
        :onload="() => isLoaded = true"
    >
        {{ loadingText }}
    </iframe>
</template>

<script>
    export default {
        name: 'GFormComponent',

        props: {
            formId: {
                type: String,
                required: true,
            },

            height: {
                type: Number,
                default: 810,
            },

            loadingText: {
                type: String,
                default: 'Loading form...',
            },
        },

        data() {
            return {
                isLoaded: false,
            };
        },
    };
</script>
