<template>
    <nav
        class="fixed flex items-center justify-around xl:justify-center w-full bg-body no-select"
        :class="{ [textSizes]: true, [heights]: true }"
    >
        <router-link
            :to="{ name: 'home' }"
            class="hidden sm:block mx-1 sm:mx-3 md:mx-4 lg:mx-8"
        >
            HOME
        </router-link>

        <router-link
            v-for="(route, i) in routes"
            :key="i"
            :to="{ name: route }"
            class="uppercase mx-1 sm:mx-3 md:mx-4 lg:mx-8"
        >
            {{ route }}
        </router-link>
    </nav>
</template>

<script>
    // Library
    import { defineComponent, ref } from 'vue';

    export default defineComponent({
        name: 'NavBar',

        setup() {
            const heights = ref([
                'h-8', 'sm:h-12', 'md:h-12', 'lg:h-14', 'xl:h-14', '2xl:h-14',
            ].join(' '));

            const textSizes = ref([
                'text-sm', 'xs:text-base', 'sm:text-xl', 'md:text-xl', 'lg:text-2xl', 'xl:text-2xl',
            ].join(' '));

            return {
                heights,
                textSizes,
                routes: [
                    'about',
                    'releases',
                    'videos',
                    'press',
                    'reviews',
                    'shop',
                    'contact',
                ],
            };
        },
    });
</script>
